<template>
  <div class="search">
    <div class="searchInfo">
      <van-search
        v-model="search_word"
        show-action
        shape="round"
        placeholder="请输入搜索关键词"
      >
        <template #action>
          <div @click="search" class="seachStyle">搜索</div>
        </template>
      </van-search>
    </div>
    <div class="content">
      <div class="history">
        <div class="top flex">
          <div class="title">搜索历史</div>
          <div class="title" @click="delAll">
            <van-button icon="delete" type="default" size="large" />
          </div>
        </div>
        <div class="bottom">
          <span
            class="label"
            v-for="(item, index) in tabList"
            :class="{ nowarp: tabList.length > 3 }"
            :key="index"
            >{{ item }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabList: [],
      search_word:''
    }
  },
  created () {},
  methods: {
    search () {
      this.tabList.unshift(this.search_word)
      this.tabList = Array.from(new Set(this.tabList))
      this.$router.push({path:'/searchInfo',query:{search_word:this.search_word}})
    },
    delAll () {
      this.$nextTick(() => {
        this.tabList = []
      })
    }
  }
}
</script>

<style lang="less" scoped>
.search {
  .content {
    padding: 0 20px;
    .history {
      padding-top: 20px;
      .top {
        color: #333333;
        font-size: 16px;
        font-weight: 600;
        .deleteImg {
          width: 16px;
          height: 16px;
        }
      }
      .bottom {
        height: 75px;
        line-height: 30px;
        word-break: normal;
        display: block;
        word-wrap: break-word;
        overflow: hidden;
        -webkit-line-clamp: 1;
      }
      // .nowarp {
      //     // word-wrap:break-word
      //     // word-wrap: break-word;
      // }
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .van-button--default {
    border: none;
  }
  .label {
    display: inline-block;
    padding: 0 15px;
    background: #cddbf6;
    border-radius: 18px;
    color: #333333;
    font-size: 14px;
    margin-right: 15px;
  }
  .van-button--large {
    background-color: #f8f9f9;
  }
  ::v-deep .seachStyle {
    font-size: 13px;
    padding: 0 10px;
    border-radius: 18px;
    color: #fff !important;
    background-color: #2163e9 !important;
  }
  ::v-deep .van-search {
    background: none;
  }
  ::v-deep .van-search__content--round {
    background-color: #fff;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  }
}
</style>
